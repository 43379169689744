import React from 'react'
import { Link, navigate, useStaticQuery, graphql } from 'gatsby'
import styled, { css, ThemeProvider } from 'styled-components'
import { Layout, Block, MetaTags } from '../components'

import logo from '../assets/images/logo.svg'

import { container, padding, bgIcon, bgImage, hoverState } from '../styles/global';
import { grey, bronze, yellow, brown } from '../styles/colors';
import themes, { getThemeData } from '../styles/themes';
import Header from '../components/Header/Header';

class Judges extends React.Component {

	state = {

	}

	componentWillMount = () => {
		this.setState({
            data: JSON.parse(this.props.data.wordpressJudgesPage.acf_json),
            globalData: JSON.parse(this.props.data.wordpressGlobal.acf_json)
		})
	}

	renderBlocks = () => {
		const { blocks } = this.state.data;

		return blocks && blocks.map((block, i) => {             
			return (
				<Block
					layout={block.acf_fc_layout}
					key={i}
					{...block}
				/>
			)
		})
	}

	render() {       
        const { data, globalData } = this.state;

        let meta_description = data.meta_description;
        if(meta_description=='') meta_description = globalData.meta_description_default

		return (
			<Layout
				// Replace with term slug 
                theme={'residential-architecture'} 
                footerImage={data.footer_bg_image && data.footer_bg_image.sizes.xlarge}
			>	
                <MetaTags
                    title={'Judges'}
                    description={meta_description}
                />
				<Wrapper>
					<Header
						showNav={true}
						showSubmit={true}
						fixed={true}
						theme={{
							colour: brown,
							background: grey
						}}
					/>
					{this.renderBlocks()}
				</Wrapper>
			</Layout>	
		)
	}
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding-top: 100px;	
`



export const query = graphql`
	query {
		wordpressJudgesPage {
			acf_json	
		}	
        wordpressGlobal {
			acf_json	
		}
	}
`

export default Judges

